// ADMIN COMPONENTS

:root {
    --width-toggle: 960px;
    --pos-toggle: -960px;
    --onglet-button-color: @color;

    @media @laptop {
        --pos-toggle: -100vw;
        --width-toggle: 100vw;
    }
}

.sidebar-toggle {
    position: fixed;
    right: var(--pos-toggle);
    max-width: var(--width-toggle);
    top: 0;
    bottom: 0;
    z-index: 8200;
    background-color: #fff;
    .flex(column,stretch,space-between);
    .transition;

    @media print {
        display: none;
    }

    &.is-open {
        right: 0;
        .box-shadow(-10px,10px,40px,0.1);

        @media @laptop {
            box-shadow: none;
        }
    }

    .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #fff;

        i {
            font-size: 3rem;
        }
    }

    .nav-bottom {
        .flex(row,center,center);

        @media @xmobile {
            flex-direction: column;
        }

        .btn {
            max-width: 250px;
            margin: 0 5px;

            @media @xmobile {
                margin-bottom: 15px;
            }
        }
    }
} // .sidebar-toggle

.sidebar-head {
    padding: 20px;
    min-height: 80px;
    color: #fff;
    background-color: @color;
    .flex(row,center,flex-start);

    > * {
        margin-bottom: 0;
    }

    strong {
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 700;
    }

    span strong {
        margin-bottom: 8px;
        display: block;
    }

    em {
        opacity: 0.5;
    }
} // .sidebar-head

.sidebar-subhead {
    min-height: 50px;
    padding: 0 25px;
    background-color: @color-s;
    .flex();

    .nav-onglets {
        --onglet-button-color: #fff;

        button {
            text-transform: uppercase;
            font-weight: 700;
            margin: 0 30px;
            font-size: 1.6rem;
        }
    }
}

.sidebar-content {
    position: relative;
    overflow-y: auto;
    flex-grow: 1;
    min-width: var(--width-toggle);
    .flex(column,stretch,flex-start);

    &.pad {
        padding: 40px;
    }

    @media @laptop {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media @mobile {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .gcols {
        margin-bottom: 25px;
    }

    > .onglet-content {
        padding: 40px;
        flex-grow: 1;

        &.nopad {
            padding: 0;
        }
    }
} // .sidebar-content

// FILTER
.filter {
    --color-filter: @color;

    position: relative;

    i {
        position: absolute;
        left: 10px;
        top: 10px;
        color: var(--color-filter);
        font-size: 2rem;
    }

    input {
        padding-left: 40px;
        margin-bottom: 0;

        &:focus {
            + i {
                color: var(--form-color-hover);
            }
        }
    }
} // search

/// ONGLETS - TABS //

.tab-nav {
    margin-bottom: 45px;
    .flex(row,center,center);

    @media @laptop {
        justify-content: flex-start;
        .no-scrollbar();
    }
}

[data-tab] {
    height: 45px;
    padding: 0 40px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 2px solid #ccc;
    color: @color;
    .flex(row,center,center);

    &:hover,
    &.actif {
        font-weight: 700;
        border-color: @color;
    }

    &:only-child {
        display: none;
    }
}

.bubble {
    background-color: @color;
    color: #fff;
    border-color: @color;
    margin: 0 5px;
}

.tab-item {
    display: none;

    &.open {
        display: block;
    }
    
    h2 {
        text-transform: uppercase;
        font-size: 2.4rem;
        margin-bottom: 30px;
        font-weight: 300;
    }

    h3 {
        text-transform: uppercase;
        font-size: 1.6rem;
        margin-bottom: 20px;
    }

    .head {
        h2 {
            font-size: 2.4rem;
        }

        h3 {
            font-size: 2rem;
        }
    }
} // .tab-item

// LISTE ROWS

.list-rows {
    max-width: 750px;
    margin-bottom: 30px;
}

.row-item {
    min-height: 50px;
    height: 50px;
    padding-left: 15px;
    .flex;

    a {
        color: @color;
        text-decoration: underline;
    }

    > a:first-child {
        flex-grow: 1;
    }
} // .row-item

//// PAGINATION //////

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
        margin-right: 10px;
        margin-bottom: 25px;

        &:only-child {
            display: none;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #eee;
            .rounded(50%);

            color: #333;
            height: 30px;
            width: 30px;

            &:hover {
                background-color: @fontcolor;
                color: #fff;
            }
        }

        &.active {
            a {
                background-color: @fontcolor;
                color: #fff;
            }
        }

        &.disabled {
            a,
            a:hover {
                background-color: transparent;
                color: #333;
                cursor: default;
            }
        }
    }
} // pagination

// TOGGLE SIMPLE
[data-toggle] {
    cursor: pointer;
}

.toggle {
    display: none;

    &.open {
        display: block;
    }
}

// TOGGLE SECTION
.toggle-item {
    .toggle-content {
        display: none;
        margin: 10px 0;
        padding: 15px;
        border: 1px solid #eee;
    }

    &.open {
        .toggle-launcher {
            &::after {
                .rotate(180deg);
            }
        }

        .toggle-content {
            display: block;
        }
    }

    .toggle-launcher {
        height: 50px;
        background-color: #eee;
        color: @color;
        font-size: 1.5rem;
        letter-spacing: 0.1rem;
        font-weight: 400;
        padding: 0 10px;
        margin-bottom: 0;
        cursor: pointer;
        .flex(row,center,flex-start);

        &:hover {
            background-color: @color;
            color: #fff;
        }

        .title {
            flex-grow: 1;
        }

        .bubble {
            margin-left: 10px;
            border: 0 none;
            background-color: @color2;
            color: #fff;
        }

        &::after {
            .fa;
            .fal;

            content: @fa-var-arrow-down;
            .transition;
        }
    }

    &:nth-child(odd) {
        .toggle-launcher {
            background-color: #ccc;

            &:hover {
                background-color: @color;
            }
        }
    }
} // .toggle-item

.type-art {
    background-color: @color;
    height: 30px;
    font-size: 1.3rem;
    padding: 0 5px;
    margin-right: 5px;
    color: #fff;
    .flex-i(row,center,center);
    .rounded(5px);
}

.toolbar {
    background-color: tint(@color, 50%);
    margin-bottom: 50px;
    min-height: 60px;
    padding: 15px 20px;
    .flex(row,center,flex-end);
    .rounded(60px);

    > div {
        margin-bottom: 0 !important;

        > label {
            margin-bottom: 5px;
        }
    }

    label {
        margin-right: 10px;
        margin-bottom: 0;
        color: #fff;
    }

    input,
    .habillage {
        margin-right: 10px;
        margin-bottom: 0;
    }

    .flatpickr {
        max-width: 150px;
    }

    .select {
        min-width: 300px;
        max-width: 300px;
    }

    .habillage {
        max-width: 300px;
        min-width: 160px;
    }

    input[type="submit"] {
        margin-left: 10px;
    }
} // .toolbar

.no-content {
    margin-bottom: 4px;
    text-align: center;

    p {
        margin-bottom: 25px;
    }
}

//// POPUP /////
body {
    &.mode-popup {
        overflow: hidden;

        &::after {
            display: block;
            content: '';
            position: fixed;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            backdrop-filter: blur(6px);
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 7999;
        }

        &.shadow-high {
            &::after {
                z-index: 8998;
            }
        }
    }   
}

.popup {
    display: none;
    color: #333;
    position: fixed;
    top: 8%;
    left: 50%;
    height: auto;
    width: 90%;
    max-width: 790px;
    margin-left: -395px;
    z-index: 8999;
    overflow-y: auto;
    .box-shadow(0,0,20px,0.2);
    .rounded(@rounded-item);

    &.init-of {
        overflow: initial;
    }

    &.popup-wide {
        max-width: 1500px;
        left: 0;
        right: 0;
        margin: auto;
        overflow-y: scroll;
        max-height: 80vh;
    }

    &.popup-full {
        .popup-content {
            min-height: 50vh;
        }
    }

    @media screen and (max-width: 880px) {
        /* 640 */
        top: 5%;
        left: 5%;
        right: 5%;
        width: auto;
        max-width: 90%;
        margin-left: 0;
    }

    @media @mobile {
        /* 640 */
        max-width: 100%;
    }

    .popup-heading {
        background-color: @color;
        color: #fff;
        padding: 0 55px 0 15px;
        height: 50px;
        margin-bottom: 0;
        border-top-right-radius: @rounded-item;
        border-top-left-radius: @rounded-item;
        .flex(row,center,flex-start);

        i {
            margin-right: 10px;
            color: tint(@color, 50%);
            font-size: 1.8rem;
        }
    }

    .popup-content {
        padding: 15px;
        padding-top: 25px;
        background-color: #fff;
        border-bottom-right-radius: @rounded-item;
        border-bottom-left-radius: @rounded-item;
    }

    h3 {
        text-transform: uppercase;
        font-size: 1.8rem;
        margin-bottom: 20px;

        @media @xmobile {
            font-size: 1.5rem;
        }
    }

    .cols {
        display: flex;
        justify-content: space-between;

        > div {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    &.open {
        display: block;
    }

    .close-popup {
        position: absolute;
        right: 20px;
        top: 10px;
        color: #fff;

        i {
            font-size: 2.8rem;
        }

        &:hover {
            color: @color2;
        }
    }
} // .popup

[data-tooltip] {
    position: relative;
    .transition;

    &::before {
        position: absolute;
        left: 50%;
        margin-left: -3px;
        top: 10px;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 10px 6px;
        border-color: transparent transparent @fontcolor transparent;
        opacity: 0;
        z-index: -1;
        .transition;
    }

    &::after {
        content: attr(data-tooltip);
        position: absolute;
        left: -20px;
        right: -20px;
        top: 20px;
        background-color: @fontcolor;
        color: #fff;
        text-transform: none;
        padding: 10px;
        font-size: 1.25rem;
        opacity: 0;
        z-index: -1;
        .rounded(4px);
        .flex(row,center,center);
        .transition;
    }

    &:hover {
        &::before {
            opacity: 1;
            z-index: 999;
            top: 30px;
        }

        &::after {
            opacity: 1;
            top: 40px;
            z-index: 999;
        }
    }
} // [data-tooltip]

// ONGLETS
.nav-onglets {
    .flex(row,center,center);

    button {
        opacity: 0.5;
        color: var(--onglet-button-color);
        .flex(row,center,center);
        .transition;

        &.actif,
        &:hover {
            opacity: 1;
        }

        &:only-child {
            display: none;
        }
    }
}

.onglet-content {
    display: none;

    h2 {
        margin-bottom: 20px;
    }

    &.open {
        display: block;
    }
}

// UPLOAD BOX
.bloc-upload {
    h3,
    .btn {
        margin-bottom: 20px;
    }

    #container {
        margin-top: 20px;
    }

    .list-files {
        min-height: 150px;
        background-color: #eee;
        border: 2px dashed #eee;
        margin-bottom: 30px;
        padding: 20px;
        font-size: 1.5rem;
        text-align: left;
        .rounded(10px);

        &::before {
            content: @fa-var-file-upload;
            font-size: 5rem;
            color: #ddd;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -25px;
            margin-left: -18px;
            .fa;
        }

        &:hover {
            border-color: @color;
            background-color: transparent;

            &::before {
                color: @color;
            }
        }

        &:not(:empty) {
            &::before {
                display: none;
            }
        }

        li {
            padding-bottom: 15px;
            .flex(row,center,space-between);

            @media @mobile {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        em {
            font-style: italic;
        }
    } // .list-files

    .filename {
        @media @mobile {
            margin-bottom: 10px;
        }
    }

    .gauge {
        width: 50%;
        .flex(row,center,flex-end);

        @media @mobile {
            width: 95%;
        }

        b {
            margin-left: 10px;
        }
    }

    .file-action {
        display: none;

        .btn {
            margin-bottom: 0;
            margin-left: 10px;
        }
    }

    .complete {
        .gauge {
            display: none;
        }

        .file-action {
            display: flex;
        }
    }
} // .bloc-upload

// JAUGE
.jauge {
    position: relative;
    overflow: hidden;

    &.color {
        --color-projet: @color;
    }

    &.color2 {
        --color-projet: @color2;
    }
    
    .jauge-container {
        opacity: 0.3;
        background-color: var(--color-projet);
        .rounded(10px);

        .vert& {
            height: 100%;
            width: 20px;
        }

        .horz& {
            width: 100%;
            height: 25px;

            .mini& {
                height: 10px;
            }
        }
    }

    .fill {
        position: absolute;
        background-color: var(--color-projet);
        .rounded(10px);

        .vert& {
            left: 0;
            right: 0;
            bottom: 0;
             height: var(--val);
        }

        .horz& {
            top: 0;
            bottom: 0;
            left: 0;
            width: var(--val);
        }
    }
}
