// FONTS

@font-face {
    font-family: "Radikal";
    src:
        local('Radikal'),
        url('../fonts/radikal-webfont.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    src:
        local('Radikal Light'),
        local('RadikalLight'),
        url('../fonts/radikal_light-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    src:
        local('Radikal Light Italic'),
        local('RadikalLightItalic'),
        url('../fonts/radikal_light_italic-webfont.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    src:
        local('Radikal Bold'),
        local('RadikalBold'),
        url('../fonts/radikal_bold-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    src:
        local('Radikal Bold Italic'),
        local('RadikalBoldItalic'),
        url('../fonts/radikal_bold_italic-webfont.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    src:
        local('Radikal Black'),
        local('RadikalBlack'),
        url('../fonts/radikal_black-webfont.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
