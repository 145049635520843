@charset "utf-8";

/*
-- PROFIL WEB - 2014-2020
-- Manu.L
*/

@import "_var";
@import "_mixins";
@import "_fontawesome6-var";
@import "_fontawesome6-core";
@import "_fontawesome6-duotone";
@import "_fonts";
@import "_reset";
@import "_basics";
@import "_forms";
@import "_commons";
@import "_components";
@import "_login";
@import "_pages";
