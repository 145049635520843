// ADMIN COMPONENTS

//// BOUTONS ////
:root {
    --width-btn: 40px;
    --avatar-size: 50px;
}

.btn {
    height: var(--width-btn);
    border: 2px solid transparent;
    letter-spacing: 0.1rem;
    font-size: 1.3rem;
    padding: 0 20px;
    text-transform: uppercase;
    .rounded(25px);
    .flex-i(row,center,center);

    .head &,
    .action & {
        @media @laptop {
            padding: 0;
            width: var(--width-btn);
            min-width: var(--width-btn);
        }
    }

    i {
        margin-right: 10px;
        font-size: 2rem;

        .head &,
        .action & {
            @media @laptop {
                margin-right: 0;
            }
        }

        &:only-child {
            margin-right: 0;
        }
    }

    span {
        font-weight: 400;
        text-transform: uppercase;

        .head &,
        .action & {
            @media @laptop {
                display: none;
            }
        }
    }

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }

    &.fixed {
        position: fixed;
        right: 40px;
        bottom: 60px;
        z-index: 4500;

        @media @xmobile {
            right: 20px;
            bottom: 20px;
        }
    }

    &.btn-round,
    &.btn-del {
        padding: 0;
        width: var(--width-btn);
        min-width: var(--width-btn);
        .rounded(50%);

        > * {
            margin: 0;
        }
    }

    &.btn-bare {
        color: #fff;

        &:hover {
            color: @color2;
        }
    }

    &.btn-sml {
        --width-btn: 30px;
    }

    &.btn-big {
        --width-btn: 80px;

        i {
            font-size: 3.2rem;
        }
    }
} // .btn

.btn-primary {
    .btn-color(@color, #fff);

    &.invert {
        .btn-color-invert(@color, #fff);
    }
}

.btn-secondary {
    .btn-color(@color2, #fff);

    &.invert {
        .btn-color-invert(@color2, #fff);
    }
}

.btn-warning {
    .btn-color(@warning, #fff);

    &.invert {
        .btn-color-invert(@warning, #fff);
    }
}

.btn-alert {
    .btn-color(@alert, #fff);

    &.invert {
        .btn-color-invert(@alert, #fff);
    }
}

.btn-back {
    margin-left: 15px;

    i {
        margin-right: 10px;

        @media @laptop {
            margin-right: 0;
        }
    }
}

.btn-del,
.btn-inactif {
    .btn-color-invert(@alert, #fff);
}

.btn-actif {
    .btn-color-invert(@valid, #fff);
}

.btn-del {
    padding: 0;
    width: var(--width-btn);
    max-width: var(--width-btn);
}

// FLAG
.flag {
    padding: 0 10px;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    background-color: @color;
    min-height: 25px;
    .flex-i(row,center,center);
    .rounded(12px);
}

// MESSAGES INFO
.msg-info {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    color: #fff;
    .rounded(20px);

    &::before {
        .fa;

        font-size: 2.4rem;
        margin-right: 20px;
    }

    &.flash {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9000;
        height: 0;
        font-size: 1.8rem;
        text-transform: uppercase;
        transition: all ease 0.15s;
        opacity: 0;
        text-align: left;
        .rounded(0);

        &.open {
            height: 60px;
            opacity: 1;
        }
    }
}

.valid {
    background-color: fadeout(@valid, 10%);

    &::before {
        content: @fa-var-check;
    }
}

.delete {
    background-color: fadeout(@valid, 10%);

    &::before {
        content: @fa-var-trash-check;
    }
}

.error {
    background-color: fadeout(@alert, 10%);

    &::before {
        content: @fa-var-ban;
    }
}

.warning {
    background-color: fadeout(@warning, 10%);

    &::before {
        content: @fa-var-exclamation-triangle;
    }
}

// AVATAR
.avatar {
    position: relative;
    width: var(--avatar-size);
    max-width: var(--avatar-size);
    height: var(--avatar-size);
    overflow: hidden;
    .rounded(50%);
    .flex(row,center,center);

    &::before {
        .fa();

        content: @fa-var-user-cowboy;
        position: absolute;
        z-index: 3;
        inset: 0;
        font-size: 3rem;
        color: @color-s;
        background-color: #eee;
        .flex(row,center,center);
    }

    img {
        position: relative;
        z-index: 4;
        display: block;
        max-width: 100%;
    }
}

// CUSTOM SLICK
.slick-list {
    width: 100%;
}

// CUSTOM FLATPICKR
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background-color: @color;
    border-color: @color;
}

// MAP
.map {
    height: 500px;
    position: relative;
    width: 100%;
    margin-bottom: 35px;
}

// CUSTOM DRAGULA
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    cursor: grab;
    .flex(row,center,space-between);
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    user-select: none !important;
}

.gu-transit {
    background-color: @color2;
    color: #fff;
    opacity: 0.2;
    width: 100%;
    display: table-row;
}

// FIX POPUP CK SYSTEM
.listing .cke_dialog {
    width: auto;
    margin-bottom: 0;
}
