/////// GENERAL /////
html {
    font-size: 62.5%;
}

body {
    font-size: 1.4rem;
    line-height: 1.4;
    letter-spacing: 0.07rem;
    font-family: @font;
    min-height: 100vh;
    background-color: tint(@color; 95%);
    color: @fontcolor;
    padding-top: 100px;
    .transition;
    .flex(column, stretch, flex-start);

    main {
        flex-grow: 1;
    }
}

// TOOLS
.txt {
    letter-spacing: 0;
}

.clear {
    clear: both;
}

.hide {
    display: none;
}

.hidden {
    visibility: hidden;
}

a {
    color: @color;
    text-decoration: none;
    .transition;

    &:hover {
        text-decoration: none;
        color: @color2;
    }

    @media print {
        text-decoration: underline;
    }
}

strong,
b {
    font-weight: 700;
}

abbr {
    border-bottom: 1px dotted #555;
}

sup {
    vertical-align: super;
    font-size: 0.8em;
}

.blur {
    opacity: 0.4;
}

.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 1500px;
    position: relative;

    @media @desktop-wide {
        max-width: 100%;
        padding: 0 50px;
    }
}

.main-content {
    .space {
        padding-top: 30px;
        margin-bottom: 30px;
    }

    .space-small {
        padding-top: 10px;
        margin-bottom: 10px;
    }
} // .main-content

/// LAYOUT ///
.cols {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .col-full {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        margin-bottom: 20px;
    }

    .col-3-4 {
        max-width: 65%;
        min-width: 650px;
        width: 100%;
        margin-bottom: 20px;

        @media @laptop {
            min-width: auto;
        }
    }

    .col2 {
        width: 48%;
        max-width: 48%;
        min-width: 650px;
        margin-bottom: 20px;

        @media @desktop-wide {
            max-width: 100%;
            min-width: auto;
        }
    }

    .col3 {
        width: 100%;
        max-width: 32%;
        min-width: 450px;
        margin-bottom: 20px;

        @media @desktop-wide {
            max-width: 48%;
            min-width: auto;
        }

        @media @laptop {
            max-width: 100%;
        }
    }
}

.gcols {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: stretch;
    grid-gap: 30px;

    &.gcol2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &.gcol3 {
        grid-template-columns: repeat(3, 1fr);
    }

    &.gcol3-2 {
        grid-template-columns: 2fr 1fr;
    }
}

// HEAD PAGE

.title-head {
    margin-bottom: 20px;
    .flex();

    .h1 {
        text-transform: none;
        width: auto;
        border: 0 none;
    }

    nav {
        .flex(row,center,flex-end);

        > * {
            margin-left: 15px;
        }
    }
}

// HEADINGS
h1,
.h1 {
    font-size: 3rem;
    text-transform: uppercase;
    margin-bottom: 20px;
    line-height: 1.2;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid tint(@color, 80%);
    .flex(row,flex-end,space-between);

    .title {
        order: -1;
    }

    i {
        background-color: @color2;
        order: 2;
        color: #fff;
        padding: 10px;
        font-size: 2rem;
        border-radius: 10px;
        box-shadow: 0 2px 5px 2px rgba(10, 38, 70, 0.1);
        margin-bottom: 4px;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        .flex(row,center,center);
    }

    a {
        position: relative;

        &:hover {
            span {
                display: flex;
            }
        }

        span {
            display: none;
            position: absolute;
            padding: 10px;
            top: 0;
            text-transform: uppercase;
            right: 50px;
            white-space: nowrap;
            font-size: 1.4rem;
            background-color: #fff;
        }

        i {
            background-color: @color2-s;
        }
    }
}

h2,
.h2 {
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 35px;
    line-height: 1.1;
}

// 404
.er404 {
    .main-content {
        min-height: 715px;
        background-color: #ebebeb;

        &::before {
            display: block;
            content: '';
            background-color: #fff;
            height: 290px;
            width: 100%;
        }

        h1 {
            text-align: center;
            margin-top: -245px;
        }
    }
} // .er404

.outdated-browser {
    padding: 20px 0;
    text-align: center;
    background-color: @color2;

    .wrap {
        max-width: 800px;
    }

    h3,
    p {
        margin-bottom: 20px;
    }

    nav {
        .flex(row,center,center);

        a:not(:first-child) {
            margin-left: 15px;
        }
    }
} // .outdated-browser

.ck {
    h2,
    h3,
    h4 {
        padding-top: 20px;
    }

    p {
        margin-bottom: 10px;
    }
}
