// PAGES

footer {
    height: 50px;
    background-color: tint(@color; 95%);
    .flex(row,center,center);
    
    @media print {
        display: none;
    }

    .pw-powa {
        color: tint(@color; 70%);
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.25rem;
    }

    a {
        color: tint(@color; 70%);

        &:hover {
            color: @color;
        }
    }
}