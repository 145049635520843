// LOGIN

.login {
    text-align: center;

    .content {
        margin: auto;
        .flex(column,center,center);
    }

    figure {
        margin-bottom: 50px;
        max-width: 220px;
    }

    h2 {
        font-family: @font;
        color: @color2-s;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 3.9rem;
        margin-bottom: 40px;
        text-align: center;
    }

    footer {
        opacity: 0.5;
        background-color: transparent;
    }
} // .login

.form-login {
    --form-color: @color;
    --form-bgcolor: #fff;
    --form-bgcolor-hover: transparent;
    --form-color-hover: @color;

    margin-bottom: 30px;
    min-width: 430px;
    .flex(column,center,flex-start);

    @media @mobile {
        min-width: 0;
        padding: 0 10px;
    }

    input {
        width: 100%;
    }
}

.lost-pass {
    color: @color;
    text-align: center;
    font-style: italic;
    font-size: 1.4rem;

    &:hover {
        text-decoration: underline;
    }
}

.form-lostpass {
    --form-color: @color;
    --form-bgcolor: #eee;
    --form-bgcolor-hover: transparent;
    --form-color-hover: @color;
}
