// FONT AWESOME 6.3.0 - CORE

@font-face {
    font-family: "Font Awesome 6 Pro";
    src: url('../fonts/fa-thin-100.woff2') format('woff2');
    font-style: normal;
    font-weight: 100;
    font-display: block;
}

@font-face {
    font-family: "Font Awesome 6 Pro";
    src: url('../fonts/fa-light-300.woff2') format('woff2');
    font-style: normal;
    font-weight: 300;
    font-display: block;
}

@font-face {
    font-family: "Font Awesome 6 Pro";
    src: url('../fonts/fa-regular-400.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: block;
}

@font-face {
    font-family: "Font Awesome 6 Pro";
    src: url('../fonts/fa-solid-900.woff2') format('woff2');
    font-style: normal;
    font-weight: 900;
    font-display: block;
}

@font-face {
    font-family: "Font Awesome 6 Brands";
    src: url('../fonts/fa-brands-400.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    font-display: block;
}

[class^="fa-"],
[class*=" fa-"],
.fa {
    font-family: "Font Awesome 6 Pro";
    speak: never;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fat,
.fa-thin {
    font-weight: 100;
}

.fal,
.fa-light {
    font-weight: 300;
}

.far,
.fa-regular {
    font-weight: 400;
}

.fas,
.fa-solid {
    font-weight: 900;
}

.fab,
.fa-brands {
    font-family: "Font Awesome 6 Brands";
}

// ICONS
each(.fa-icons(), {
  .fa-@{key}::before { content: @value; }
});

// BRANDS
each(.fa-brand-icons(), {
  .fa-@{key}::before { content: @value; }
});
