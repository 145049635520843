//////// FORMS  ///////
:root {
    --form-bgcolor: transparent;
    --form-bgcolor-hover: #fff;
    --form-color: @color;
    --form-color-hover: @color2;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="tel"],
input[type="url"],
textarea {
    appearance: none;
    color: var(--form-color);
    padding: 0 20px;
    width: 100%;
    height: 45px;
    font-size: 1.5rem;
    font-family: @font;
    border: 1px solid var(--form-bgcolor);
    margin-bottom: 20px;
    background-color: var(--form-bgcolor);
    .transition;
    .rounded(24px);
    .placeholder;

    &:autofill {
        box-shadow: 0 0 0 1000px #fff inset;

        &:focus {
            box-shadow: 0 0 0 1000px #fff inset;
            color: @color;
        }
    }

    &:focus {
        outline: none;
        border-color: var(--form-color-hover);
        color: var(--form-color-hover);
        background-color: var(--form-bgcolor-hover);
    }
}

::placeholder {
    font-style: italic;
    opacity: 0.7;
}

input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        appearance: none;
    }
}

input[type="number"] {
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }
}

input[type="submit"] {
    font-family: @font;
    appearance: none;
    font-size: 1.5rem;
    padding: 0 20px;
    min-height: 40px;
    height: 40px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    border: 1px solid transparent;
    background-color: @color;
    color: #fff;
    cursor: pointer;
    .flex-i(row,center,center);
    .rounded(20px);

    &:hover {
        color: @color;
        background-color: transparent;
        border-color: @color;
    }
}

button {
    font-family: @font;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    .rounded(0);
}

textarea {
    resize: vertical;
    padding: 20px;
    min-height: 200px;
}

label {
    font-size: 1.5rem;
    display: block;
    color: @fontcolor;
    text-align: left;
    margin-bottom: 10px;

    em {
        display: block;
        color: #999;
        margin-top: 5px;
        text-transform: none;
        font-size: 1.33rem;
    }
}

////// BOUTONS CHECKBOX /////
.checkbox {
    position: relative;
    z-index: 1;

    label {
        font-family: @font;
        text-transform: none;
        display: flex;
        position: relative;
        cursor: pointer;

        &::before {
            content: '';
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: 2px solid #ddd;
            margin-right: 10px;
            padding-right: 15px;
        }

        &::after {
            position: absolute;
            left: 3px;
            top: 3px;
            color: transparent;
            font-size: 1.4rem;
            content: @fa-var-check;
            .transition;
            .fa;
            .fas;
        }
    }

    input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        outline: none;

        &:checked + label {
            &::before {
                border-color: @color;
                outline: none;
            }

            &::after {
                color: @color;
                outline: none;
            }
        }
    }
} // checkbox

///// LISTE DE BOUTONS RADIO
.list-radio {
    position: relative;
    z-index: 1;
    margin-bottom: 13px;

    li {
        margin-bottom: 5px;
    }

    input[type="radio"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        border: 0 none;
        z-index: -1;
        appearance: none;
        .rounded(50%);

        & + label {
            position: relative;
            display: flex;
            text-transform: none;
            font-family: @font;
            cursor: pointer;

            &::before {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 10px;
                background-color: #eee;
                .rounded(50%);
                .transition();
            }

            &::after {
                position: absolute;
                left: 6px;
                top: 6px;
                content: '';
                height: 8px;
                width: 8px;
                background-color: #eee;
                .rounded(50%);
                .transition();
            }
        }

        &:checked + label {
            font-weight: 700;

            &::before {
                border: 1px solid @color;
                background-color: #fff;
            }

            &::after {
                background-color: @color;
            }
        }
    }
} // list-radio

///// SELECT CUSTOM ////
.habillage {
    height: 45px;
    //max-width: 250px;
    margin-bottom: 20px;
    position: relative;
    background-color: transparent;
    flex-grow: 1;
    overflow: hidden;
    border: 1px solid @color;
    min-width: 200px;
    border-radius: 50px;

    &::after {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 2.3rem;
        height: 50px;
        width: 50px;
        background-color: @color;
        color: #fff;
        z-index: 97;
        content: @fa-var-chevron-right;
        .flex(row,center,center);
        .fa;
        .rotate(90deg);
    }

    select {
        position: absolute;
        left: 0;
        top: 0;
        appearance: none;
        background-color: transparent;
        text-overflow: '';
        border: 0 none;
        outline: none;
        font-family: inherit;
        font-size: 1.5rem;
        height: 100%;
        width: 100%;
        padding: 0 45px 0 15px;
        z-index: 98;
    }
} // habillage

.input-file {
    width: auto;
    max-width: 300px;

    label {
        cursor: pointer;
        margin-bottom: 0;
        font-size: 1.3rem;
        font-weight: 400;
    }

    i {
        font-size: 2.5rem;
        margin-right: 5px;
        font-weight: 300;
    }

    input {
        display: none;
    }

    .file-selected {
        font-weight: 700;
        font-size: 1.5rem;
        text-align: left;
        padding: 0;
        margin-bottom: 0;
        margin-top: 15px;
        position: relative;
        .rounded(4px);

        &:not(:empty) {
            background-color: tint(@color, 70%);
            margin-bottom: 20px;
            padding: 10px;

            &::before {
                content: "Fichier sélectionné : ";
                display: block;
                font-style: italic;
                font-weight: 300;
                color: @black;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 15px;
                top: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 10px 12px;
                border-color: transparent transparent tint(@color, 70%) transparent;
            }
        }
    } // .file-selected
} // .input-file

.form {
    margin-bottom: 30px;
    flex-wrap: wrap;
    .flex(column,center,center);

    p {
        width: 100%;
        margin-bottom: 20px;
    }

    input[type="text"],
    input[type="password"],
    input[type="search"],
    input[type="email"],
    input[type="number"],
    input[type="date"],
    input[type="tel"],
    input[type="url"],
    label,
    textarea,
    .habillage,
    .checkbox {
        width: 100%;
    }

    .habillage,
    .checkbox {
        margin-bottom: 20px;
    }

    .buttons {
        width: 100%;
        padding-top: 10px;
        flex-wrap: wrap;
        .flex(row,center,flex-start);

        button {
            margin-right: 10px;
            margin-bottom: 20px;
        }
    }
}

.buttons-container {
    padding-top: 20px;
    margin-bottom: 20px;
    .flex(row,center,flex-start);

    .btn {
        margin-right: 20px;
    }
}

.form-saisie {
    input[type="text"],
    input[type="password"],
    input[type="search"],
    input[type="email"],
    input[type="number"],
    input[type="date"],
    input[type="tel"],
    input[type="url"],
    textarea {
        width: 100%;
    }

    .cols {
        align-items: flex-start;
    }

    .autocompletion {
        position: relative;
    }

    .suggestionsBox {
        background-color: @color;
        color: #fff;
        margin: 0 10px;
        position: absolute;
        top: 46px;
        left: 0;
        right: 0;
        z-index: 888;

        li {
            margin-bottom: 5px;
            padding: 5px 10px;
            .transition;

            &:hover {
                background-color: @color2;
            }
        }
    }
}
