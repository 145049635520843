// FONT AWESOME 6.3.0 - DUOTONE

@font-face {
    font-family: "Font Awesome 6 Duotone";
    src: url('../fonts/fa-duotone-900.woff2') format('woff2');
    font-style: normal;
    font-weight: 900;
    font-display: block;
}

.fad,
.fa-duotone {
    position: relative;
    font-family: "Font Awesome 6 Duotone";
    font-weight: 900;
    letter-spacing: normal;

    &::before {
        position: absolute;
        opacity: 1;
    }

    &::after {
        opacity: 0.4;
    }
}

// ICONS
each(.fa-icons(), {
    .fad.fa-@{key}::after, .fa-duotone.fa-@{key}::after {
        content: %("%s%s", @value, @value);
    }
});
